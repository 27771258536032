import axios from "axios";
import config from "@/config.js";

let axiosInstance = axios.create({
  baseURL: `${config.apiUrl()}`
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
   
    return Promise.reject(error);
  }
);

export default axiosInstance;
