const HomePage = () => import("./pages/HomePage.vue");



export const NipponRoutes = [
  {
    path: '/nippon',
    component: HomePage,
    meta: {
      title: 'Nippon Paints'
    }
  }
];
