const DellUserForm = () => import("./pages/DellUserForm.vue");



export const DellRoutes = [
  {
    path: '/dellretail',
    component: DellUserForm,
    meta: {
      title: 'Dell India'
    }
  }
];
