<template>
  <div>
    <SnackBar />
    <router-view v-if="isMobile" />
    <div class="desktop text-center d-flex align-center ma-16" v-else>
      This content is visible only on mobile screens.
    </div>
  </div>
</template>

<script>
import SnackBar from "@/components/SnackBar.vue";
export default {
  name: "App",

  components: {
    SnackBar
  },

  data: () => ({
    isMobile: false
  }),
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.outerWidth <= 600;
    }
  }
};
</script>
<style scoped>
.desktop {
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-weight: 500;
  color: black;
  min-height: 100vh;
}
.v-application--wrap {
  min-height: unset;
}
</style>
