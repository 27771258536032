<template>
  <div class="hello">
    <v-row class="d-flex justify-center align-center text-center">
      <v-col>
        <h1 class="pt-10">Microsites Quick links</h1>
        <router-link to="/cadburyperk/login">
          <v-btn rounded x-large color="yellow" class="ma-10">Cadbury Perk</v-btn>
        </router-link>
      </v-col>
      <v-col>
        <router-link to="/madbury/login">
          <v-btn rounded x-large color="yellow" class="ma-10">Madbury Chocolate</v-btn>
        </router-link></v-col
      >
    </v-row>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: white;
}
</style>
