import * as MT from "./mutationTypes";

export default {
  [MT.SET_SNACKBAR]: (state, data) => (state.snackbar = { ...data, status: true }),
  [MT.APPEND_TO_FORM]: (state, payload) => state.formData = { ...state.formData, ...payload },
  [MT.RESET_FORM]: (state) => state.formData = {},
  [MT.SAVE_PIXELS]: (state, pixel) => state.tyPixels = {...pixel},
  [MT.SET_USER_PROFILE]: (state, userData) => state.userProfile = userData,
  [MT.RESET_USER_PROFILE]: (state) => state.userProfile = {}
};
