const TruecallerHome = () => import("./TruecallerHome.vue");


export const FlarieRoutes = [
  {
    path: '/truecallerdma',
    component: TruecallerHome,
    meta: {
      title: 'Truecaller'
    }
  }
];
