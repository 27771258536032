<template>
  <div class="page-404">
    <div class="mt-8">
      <div class="page-404__title">404</div>
      <div class="page-404__subtitle">The page you're looking for does not exists.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound"
};
</script>
<style lang="scss">
.page-404 {
  &__title {
    color: #ffffff;
    font-size: 6rem;
    text-align: center;
  }
  &__subtitle {
    color: white;
    font-size: 2rem;
    text-align: center;
  }
  &__image {
    padding-top: 1rem;
    text-align: center;
    img {
      width: 250px;
    }
  }
  background-color: #0087ff;
  min-height: 100vh;
}
</style>
